export const SET_FINANCIAL_BROKER_DATA = 'SET_FINANCIAL_BROKER_DATA';
export const SET_SELECTED_FINANCIAL_BROKER = 'SET_SELECTED_FINANCIAL_BROKER';
export const SET_FINANCIAL_BROKERS_SEARCH_RESULTS = 'SET_FINANCIAL_BROKERS_SEARCH_RESULTS';
export const GET_FINANCIAL_BROKERS_BY_SIREN = 'GET_FINANCIAL_BROKERS_BY_SIREN';
export const GET_FINANCIAL_BROKERS_BY_SIREN_FULFILLED = 'GET_FINANCIAL_BROKERS_BY_SIREN_FULFILLED';
export const GET_FINANCIAL_BROKERS_BY_SIREN_REJECTED = 'GET_FINANCIAL_BROKERS_BY_SIREN_REJECTED';

export const RESET_FINANCIAL_BROKER_DATA = 'RESET_FINANCIAL_BROKER_DATA';
export const RESET_SELECTED_FINANCIAL_BROKER = 'RESET_SELECTED_FINANCIAL_BROKER';
export const RESET_FINANCIAL_BROKER_ID = 'RESET_FINANCIAL_BROKER_ID';
export const GET_FINANCIAL_BROKER_DATA_FROM_INSEE = 'GET_FINANCIAL_BROKER_DATA_FROM_INSEE';
export const GET_FINANCIAL_BROKER_DATA_FROM_INSEE_PENDING =
  'GET_FINANCIAL_BROKER_DATA_FROM_INSEE_PENDING';
export const GET_FINANCIAL_BROKER_DATA_FROM_INSEE_FULFILLED =
  'GET_FINANCIAL_BROKER_DATA_FROM_INSEE_FULFILLED';
export const GET_FINANCIAL_BROKER_DATA_FROM_INSEE_REJECTED =
  'GET_FINANCIAL_BROKER_DATA_FROM_INSEE_REJECTED';
export const SET_FINANCIAL_BROKER_AUTOCOMPLETE_INSEE_SIREN =
  'SET_FINANCIAL_BROKER_AUTOCOMPLETE_INSEE_SIREN';
