import {
  SET_INVEST_DETAILS_ERROR,
  CLEAR_INVEST_DETAILS_ERROR,
  SHOW_IDENTITY_PROOF_MODAL,
  SET_PART_TYPES_LIST,
  SET_COMPANIES_IDENTIFICATION_INFOS,
} from '../actions/invest-details.const';
import * as companyConst from '../../../shared/actions/const/company.const';
import * as financialBrokerConst from '../../../shared/actions/const/financial-broker.const';
import * as investmentsBulkConst from '../../investments-bulk/actions/investments-bulk.const';

const defaultState = {
  isError: false,
  errorMessage: '',
  showIdentityProofModal: false,
  isLoadingInseeDatas: false,
  partTypesList: [],
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_INVEST_DETAILS_ERROR:
    case investmentsBulkConst.BULK_SUBMIT_FORM_REJECTED:
      return {
        ...state,
        isError: true,
        errorMessage: action.errorMessage,
      };
    case CLEAR_INVEST_DETAILS_ERROR:
      return {
        ...state,
        isError: false,
        errorMessage: '',
      };
    case SHOW_IDENTITY_PROOF_MODAL:
      return {
        ...state,
        showIdentityProofModal: action.open,
      };
    case companyConst.GET_COMPANY_DATA_FROM_INSEE_REJECTED:
    case companyConst.GET_COMPANY_DATA_FROM_INSEE_FULFILLED:
    case financialBrokerConst.GET_FINANCIAL_BROKER_DATA_FROM_INSEE_REJECTED:
    case financialBrokerConst.GET_FINANCIAL_BROKER_DATA_FROM_INSEE_FULFILLED:
      return {
        ...state,
        isLoadingInseeDatas: false,
      };
    case companyConst.GET_COMPANY_DATA_FROM_INSEE_PENDING:
    case financialBrokerConst.GET_FINANCIAL_BROKER_DATA_FROM_INSEE_PENDING:
      return {
        ...state,
        isLoadingInseeDatas: true,
      };

    case SET_PART_TYPES_LIST:
      return {
        ...state,
        partTypesList: action.partTypeList,
      };

    case SET_COMPANIES_IDENTIFICATION_INFOS:
      return {
        ...state,
        companiesIdentificationInfos: action.companiesInfos,
      };

    default:
      return state;
  }
};

export default reducer;
