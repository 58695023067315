// Actions
import * as CommonAction from './invest-details-common.actions';
import { dispatchChangeToInvestDetailsForm } from '../../../shared/utils/CatalizrFormUtils';
import { setAutocompleteInseeSirenSuccess } from '../../../shared/actions/actions/financial-broker.actions';
import * as CompanyAction from '../../../shared/actions/actions/company.actions';

// Constants
import { INVEST_DETAILS_REDUX_OBJECTS_NAMING } from '../../../shared/utils/CatalizrFormUtils';

// --------------------------------------------------------------------------------------------------------
// DATA INITIALIZATION
// --------------------------------------------------------------------------------------------------------

/**
 * Initializes the financial broker data into redux and the 'invest-details' redux form.
 * @param {object} investment the investment data containing the financial broker to initialize
 * @param {function} dispatch the redux dispatch function
 * @void (dispatch the result data to redux & redux-form)
 */
export const initInvestDetailsWithFinancialBrokerData = (investment, dispatch) => {
  // the financialBroker is attached to the company
  const financialBrokerData =
    investment && investment.company ? investment.company.financial_intermediary : null;
  if (financialBrokerData) {
    // the financial broker is attached to the same user of the company
    const companyUser = investment.company.user;
    dispatchFinancialBrokerData(dispatch, financialBrokerData, companyUser);
  }
};

/**
 * Dispatch the financial broker data from SIRENE API (INSEE)
 * @param {object} financialBrokerData companyDatas retrieve from SIRENE API (INSEE)
 * @param {boolean} datasFromInsee ALL company datas came from INSEE
 * @void (dispatch the result data to redux-form)
 */
export const setFinancialBrokerInseeDataToInvestDetails =
  (financialBrokerData, datasFromInsee) => dispatch => {
    const isCeasedFinancialBroker = financialBrokerData ? financialBrokerData.is_ceased : null;
    if (financialBrokerData) {
      // Set data_from_insee to true to disabled financial broker fields filled by SIRENE API
      dispatch(setAutocompleteInseeSirenSuccess(datasFromInsee));
      financialBrokerData.data_from_insee = datasFromInsee;

      dispatch(CompanyAction.setCeasedCompanyAlert(isCeasedFinancialBroker));

      dispatchFinancialBrokerData(
        dispatch,
        buildFinancialBrokerDataToDispatch(financialBrokerData),
      );
    }
  };

// --------------------------------------------------------------------------------------------------------
// DATA CREATION & UPDATE
// --------------------------------------------------------------------------------------------------------

/**
 * Creates the object containing the financial broker data to send to the validate function.
 * @param {*} financialBrokerDataFromForm the financial broker data contained in the form
 */
export const buildFinancialBrokerData = financialBrokerDataFromForm => {
  // data
  const financialBrokerData = {
    siren: financialBrokerDataFromForm.siren,
    legal_form: financialBrokerDataFromForm.legal_form,
    name: financialBrokerDataFromForm.name,
    address: financialBrokerDataFromForm.address,
    zip: financialBrokerDataFromForm.zip,
    city: financialBrokerDataFromForm.city,
    country: financialBrokerDataFromForm.country,
    bank_name: financialBrokerDataFromForm.bank_name,
    iban: financialBrokerDataFromForm.iban,
    data_from_insee: financialBrokerDataFromForm.data_from_insee,
  };

  // send the fb when the user has selected a financial broker in suggestion
  if (financialBrokerDataFromForm.id) {
    financialBrokerData.id = financialBrokerDataFromForm.id;
  }

  //TODO: used ???
  const optionalsDatas = ['boss'];
  optionalsDatas.forEach(currentProp => {
    if (financialBrokerDataFromForm[currentProp]) {
      financialBrokerData[currentProp] = financialBrokerDataFromForm[currentProp];
    }
  });
  return financialBrokerData;
};

// -----------------------------------------------------------------------------------------------
// PRIVATE METHODS
// -----------------------------------------------------------------------------------------------

const dispatchFinancialBrokerData = (dispatch, financialBrokerData, companyUser = null) => {
  const dataToDispatch = buildFinancialBrokerDataToDispatch(financialBrokerData, companyUser);
  // dispatch the financial broker data to the redux form
  dispatchChangeToInvestDetailsForm(
    INVEST_DETAILS_REDUX_OBJECTS_NAMING.financialBroker,
    dataToDispatch,
    dispatch,
  );
  // dispatch the financial broker data to redux
  dispatch(CommonAction.setFinancialBrokerData(dataToDispatch));
  // init the available legal forms
  dispatch(CommonAction.initLegalFormsAndBossStatuses());
};

const buildFinancialBrokerDataToDispatch = (financialBroker, companyUser) => {
  const financialBrokerData = {
    id: financialBroker.id,
    siren: financialBroker.siren,
    legal_form: financialBroker.legal_form,
    name: financialBroker.name,
    address: financialBroker.address,
    city: financialBroker.city,
    zip: financialBroker.zip,
    country: financialBroker.country,
    bank_name: financialBroker.bank_name,
    iban: financialBroker.iban,
    data_from_insee: financialBroker.data_from_insee,
    is_ceased: financialBroker.is_ceased,
  };
  if (companyUser) {
    financialBrokerData.user = { ...companyUser };
  }
  return financialBrokerData;
};
