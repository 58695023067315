// Const
import * as commonConst from '../actions/const/common.const';
import * as companyConst from '../actions/const/company.const';
import * as financialBrokerConst from '../actions/const/financial-broker.const';
import * as landingConst from '../../pages/landing/const/landing.const';
import * as investDetailsConst from '../../pages/invest-details/actions/invest-details.const';

export const initState = {
  id: '',
  siren: '',
  legal_form: '',
  name: '',
  address: '',
  zip: '',
  city: '',
  country: 'France',
  bank_name: '',
  iban: '',
  searchResults: [],
  selectedInForm: {},
  sirenNotUsed: true,
  legalForms: [],
  bossStatuses: [],
  user: {
    id: '',
    email: '',
  },
  data_from_insee: false,
};

const financialBroker = (state = initState, action) => {
  switch (action.type) {
    case commonConst.CLEAR_LOGOUT:
    case commonConst.CLEAR_REDUX:
    case commonConst.CLEAR_LOGOUT_PENDING:
    case landingConst.INIT_VIEW_PENDING:
    case landingConst.START_NEW_TEMPLATE_PENDING:
    case commonConst.INIT_STATE:
      return initState;

    case investDetailsConst.SET_FINANCIAL_BROKER_DATA:
    case financialBrokerConst.SET_FINANCIAL_BROKER_DATA:
      return { ...state, ...action.data };

    case financialBrokerConst.SET_FINANCIAL_BROKERS_SEARCH_RESULTS:
      return { ...state, searchResults: action.data };

    case financialBrokerConst.GET_FINANCIAL_BROKERS_BY_SIREN_FULFILLED:
      return { ...state, searchResults: action.payload.data };

    case financialBrokerConst.SET_SELECTED_FINANCIAL_BROKER:
      return {
        ...state,
        selectedInForm: action.data,
      };

    case companyConst.SET_COMPANIES_LEGAL_FORMS: {
      return {
        ...state,
        legalForms: action.legalForms,
      };
    }

    case companyConst.SET_COMPANIES_BOSS_STATUS: {
      return {
        ...state,
        bossStatuses: action.bossStatus,
      };
    }

    case financialBrokerConst.RESET_SELECTED_FINANCIAL_BROKER:
      return {
        ...state,
        selectedInForm: {},
      };

    case financialBrokerConst.RESET_FINANCIAL_BROKER_ID:
      return { ...state, id: '' };

    case financialBrokerConst.SET_FINANCIAL_BROKER_AUTOCOMPLETE_INSEE_SIREN: {
      return {
        ...state,
        data_from_insee: true,
      };
    }
    case financialBrokerConst.RESET_FINANCIAL_BROKER_DATA:
      return initState;

    default:
      return state;
  }
};

export default financialBroker;
