import * as userConst from '../const/user.const';
import * as commonConst from '../const/common.const';

import { getUserInfos, logout } from '../../api/user.api';

// specific to user account (keep user data in the store in case of email/phone modification)
export const setUserEmail = email => ({ type: userConst.SET_USER_EMAIL, email });
// specific to login page (reset user data in the store in case of email input changed : password field is disabled)
export const setUserEmailLogin = email => ({ type: userConst.SET_USER_EMAIL_LOGIN, email });

export const getUserPending = () => ({ type: userConst.GET_USER_INFOS_PENDING });
export const getUserFulfilled = user => ({ type: userConst.GET_USER_INFOS_FULFILLED, user });

export const setUserData = user => ({ type: userConst.SET_USER_DATA, user });

export const manageUser = (user, dispatch) => {
  dispatch(setUserData({ ...user }));
};

export const userInfos = () => ({
  type: 'GET_USER_INFOS',
  payload: getUserInfos(),
});

export function userLogout() {
  return dispatch => {
    dispatch({
      type: commonConst.CLEAR_LOGOUT,
      payload: logout(),
    });
  };
}

/**
 * Updates user data into redux
 * @param {object} userData containing user properties to update into redux
 */
export const updateUserData = userData => ({ type: userConst.UPDATE_USER_DATA, userData });
