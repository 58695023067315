export const INIT_INVESTMENT_TYPE = 'INVESTMENT_INIT_INVESTMENT_TYPE';
export const INIT_INVESTMENT = 'INVESTMENT_INIT_INVESTMENT';
export const INIT_INVESTMENT_BANK = 'INVESTMENT_INIT_INVESTMENT_BANK';
export const SET_INVESTMENT_TYPE_LIST = 'INVESTMENT_SET_INVESTMENT_TYPE_LIST';
export const SET_CURRENT_INVESTMENT_TYPE = 'INVESTMENT_SET_CURRENT_INVESTMENT';
export const SET_CURRENT_INVESTMENT_DISPOSAL_MODE = 'SET_CURRENT_INVESTMENT_DISPOSAL_MODE';
export const SET_CONTINUE_INVESTMENT = 'INVESTMENT_SET_CONTINUE';
export const INIT_PRODUCT = 'INVESTMENT_INIT_PRODUCT';
export const SET_PRODUCT_LIST = 'INVESTMENT_SET_PRODUCT_LIST';
export const SET_CURRENT_PRODUCT = 'INVESTMENT_SET_CURRENT_PRODUCT';
export const SET_CURRENT_COMPANY = 'INVESTMENT_SET_CURRENT_COMPANY';
export const SET_PART_LEVEL = 'INVESTMENT_SET_PART_LEVEL';
export const SET_CFI_CODE = 'INVESTMENT_SET_CFI_CODE';
export const SET_CLOSE_DATE = 'INVESTMENT_SET_CLOSE_DATE';
export const SET_REAL_ESTATE_DATE = 'INVESTMENT_SET_REAL_ESTATE_DATE';
export const SET_STATUS_PENDING = 'INVESTMENT_SET_STATUS_PENDING';
export const SET_STATUS_FULLFILED = 'INVESTMENT_SET_STATUS_FULLFILED';
export const SET_STATUS_REJECTED = 'INVESTMENT_SET_STATUS_REJECTED';
export const STATUS_CLOSED = 'CLOSED';
export const STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const STATUS_SUGGESTED = 'SUGGESTED';
export const STATUS_CANCELLED = 'CANCELLED';

export const CONFIRM_PAYMENT_RELEASE = 'CONFIRM_PAYMENT_RELEASE';
export const CONFIRM_PAYMENT_RELEASE_FULLFILED = CONFIRM_PAYMENT_RELEASE + '_FULLFILED';
export const CONFIRM_PAYMENT_RELEASE_REJECTED = CONFIRM_PAYMENT_RELEASE + '_REJECTED';

export const CUSTOM_SIGNLINK_PENDING = 'UPLOAD_DOCUMENTS_FORM_SUBMIT_CUSTOM_SIGNLINK_PENDING';
export const CUSTOM_SIGNLINK_FULFILLED = 'UPLOAD_DOCUMENTS_FORM_SUBMIT_CUSTOM_SIGNLINK_FULFILLED';
export const CUSTOM_SIGNLINK_REJECTED = 'UPLOAD_DOCUMENTS_FORM_SUBMIT_CUSTOM_SIGNLINK_REJECTED';
export const MOCK_DOCUSIGN_PENDING = 'UPLOAD_DOCUMENTS_FORM_SUBMIT_MOCK_DOCUSIGN_PENDING';
export const MOCK_DOCUSIGN_FULFILLED = 'UPLOAD_DOCUMENTS_FORM_SUBMIT_MOCK_DOCUSIGN_FULFILLED';
export const MOCK_DOCUSIGN_REJECTED = 'UPLOAD_DOCUMENTS_FORM_SUBMIT_MOCK_DOCUSIGN_REJECTED';

export const POST_CANCELLED = 'INVESTMENT_POST_CANCELLED';
export const POST_CANCELLED_PENDING = 'INVESTMENT_POST_CANCELLED_PENDING';
export const POST_CANCELLED_FULFILLED = 'INVESTMENT_POST_CANCELLED_FULFILLED';
export const POST_CANCELLED_REJECTED = 'INVESTMENT_POST_CANCELLED_REJECTED';

export const SET_INVESTMENT_LINK_ID = 'INVESTMENT_SET_INVESTMENT_LINK_ID';

export const GET_OPERATION_FILTERS_PENDING = 'GET_OPERATION_FILTERS__PENDING';
export const GET_OPERATION_FILTERS_FULLFILLED = 'GET_OPERATION_FILTERS__FULLFILLED';
export const GET_OPERATION_FILTERS_REJECTED = 'GET_OPERATION_FILTERS__REJECTED';
export const SET_COMPARTMENTS = 'INVESTMENT_SET_COMPARTMENTS';
export const SET_NOMINATIVE_STATUS = 'INVESTMENT_SET_NOMINATIVE_STATUS';
export const SET_DISTRIBUTION_FEE_BANKS = 'INVESTMENT_SET_DISTRIBUTION_FEE_BANKS';

export const SET_INVESTMENT_ERROR = 'SET_INVESTMENT_ERROR';

export const SET_INVESTMENT_CATEGORIES = 'SET_INVESTMENT_CATEGORIES';

export const SET_ADVISOR_IN_CHARGE = 'SET_ADVISOR_IN_CHARGE';
export const SET_ADVISOR_IN_CHARGE_REJECTED = 'SET_ADVISOR_IN_CHARGE_REJECTED';
