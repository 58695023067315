import { OPERATION_TEMPLATES_PATH } from '../utils/path';
import { deleteMethod, get, post, put } from './apicaller';

const postTemplate = (data, entityPath = 'bank') => {
  return post(
    `${process.env.REACT_APP_BACKEND_URL}/api/${entityPath}-operation-templates`,
    {
      activated_portfolio_types: data?.activated_portfolio_types,
      portfolio_type_id: data?.portfolio_type_id,
      company_id: data?.company_id,
      creator_id: data?.creator_id,
      activated_banks: data?.activated_banks,
      name: data?.name,
      operation_type_id: data?.operation_type_id,
    },
    [],
  );
};

const updateTemplateDocument = (templateUuid, data, entityPath = 'bank') => {
  return put(
    `${process.env.REACT_APP_BACKEND_URL}/api/${entityPath}-operation-templates/${templateUuid}/documents`,
    data,
    [],
  );
};

const updateTemplate = (templateUuid, data, entityPath = 'bank') =>
  put(
    `${process.env.REACT_APP_BACKEND_URL}/api/${entityPath}-operation-templates/${templateUuid}`,
    data,
    [],
  );
const getTemplate = (templateUuid, entityPath = 'bank') =>
  get(
    `${process.env.REACT_APP_BACKEND_URL}/api/${entityPath}-operation-templates/${templateUuid}`,
    [],
  );

/**
 *
 * @param domain
 * @param {object} currentSort
 * @param entityPath
 * @param editable
 * @returns
 */
const getAllTemplates = (domain, currentSort = null, entityPath = 'bank', editable = false) => {
  let query = editable ? '?queryData=editable_template' : '?queryData=template';
  if (domain) {
    query = `${query}&filters=${domain}`;
  }
  if (currentSort && currentSort.id && currentSort.sort) {
    query = `${query}&orderBy=${currentSort.id}&sort=${currentSort.sort}`;
  }
  return get(
    `${process.env.REACT_APP_BACKEND_URL}/api/${entityPath}-operation-templates${query}`,
    [],
  );
};

const deleteTemplate = uuid => {
  return deleteMethod(
    `${process.env.REACT_APP_BACKEND_URL}/api/${OPERATION_TEMPLATES_PATH.BY_BANK}/${uuid}`,
    {},
    [],
  );
};

export const updateCustomFields = (templateUuid, data) => {
  return put(
    `${process.env.REACT_APP_BACKEND_URL}/api/bank-operation-templates/${templateUuid}/custom-fields`,
    data,
    [],
  );
};

export {
  postTemplate,
  updateTemplate,
  getTemplate,
  getAllTemplates,
  deleteTemplate,
  updateTemplateDocument,
};
