import React from 'react';

// Components
import CustomHeader from '../../components/Table/components/Headers/CustomHeader';

// Utils
import { OVERVIEW_LABEL } from './CatalizrConstants';
import { isUserBank, isUserCompany } from './JourneyUtils';
import { selectDefaultBankName } from './CatalizrUtils';

// Assets
import IconAlertTriangle from '../../iconsPictos/iconAlertTriangle';
import IconFlag from '../../iconsPictos/iconFlag';
import IconBell from '../../iconsPictos/iconBell';
import IconChat from '../../iconsPictos/iconChat';
import IconMoney from '../../iconsPictos/iconMoney';

// to access react table setColumns
export const TABLE_COLUMNS_HEADERS = {
  // SPECIFIC BULK_TABLE
  // display for banks
  ADVISOR_EMAIL: {
    id: 'advisor_email',
    header: 'Conseiller',
    accessor: 'advisor_email',
  },

  CATEGORY: {
    id: 'category',
    header: 'Catégorie',
    accessor: 'category',
  },

  INVESTOR_NAME_SURNAME: {
    id: 'investor_name_surname',
    header: 'Investisseur',
    accessor: 'investor_name_surname',
  },
  CO_INVESTOR_NAME_SURNAME: {
    id: 'co_investor_name_surname',
    header: 'Co-Investisseur',
    accessor: 'co_investor_name_surname',
  },

  NB_PART: {
    id: 'nb_part',
    header: 'Quantité',
    accessor: 'nb_part',
  },

  PART_TYPE: {
    id: 'part_type',
    header: 'Nature',
    accessor: 'part_type',
  },

  PORTFOLIO_TYPE: {
    id: 'portfolio_type',
    header: 'Support',
    accessor: 'portfolio_type',
  },

  TOTAL_INVEST: {
    id: 'total_invest',
    header: 'Montant',
    accessor: 'total_invest',
  },

  // SPECIFIC FOR OPERATION_TEMPLATE_TABLE

  BRIEFCASE: {
    id: 'origin',
    header: 'Modèle',
    accessor: 'origin',
  },

  ACTION_BY_UUID: {
    id: 'uuid',
    header: 'Actions',
    accessor: 'uuid',
  },

  BANK_ACTIVE_TEMPLATE: {
    id: 'bank_activated_name',
    header: isUserCompany() ? 'Nombre de Banques' : 'Banques',
    accessor: 'bank_activated_name',
  },

  COUNT_CANCELLED_OPERATIONS: {
    id: 'count_cancelled_operations',
    header: isUserBank() ? 'Dossiers annulés' : 'Opérations annulées',
    accessor: 'count_cancelled_operations',
  },

  COUNT_CLOSED_OPERATIONS: {
    id: 'count_closed_operations',
    header: isUserBank() ? 'Dossiers clôturés' : 'Opérations clôturées',
    accessor: 'count_closed_operations',
  },

  COUNT_TRANSMIT_OPERATION: {
    id: 'count_transmit_operations',
    header: isUserBank() ? 'Dossiers créés' : 'Opérations créées',
    accessor: 'count_transmit_operations',
  },

  OPERATION_TYPE_ID: {
    id: 'operation_type_id',
    header: "Type d'opération",
    accessor: 'operation_type_id',
  },

  PORTFOLIO_TYPE_ID: {
    id: 'portfolio_types_id',
    header: 'Compte',
    accessor: 'portfolio_types_id',
  },

  TEMPLATE_NAME: {
    id: 'template_name',
    header: 'Nom du modèle',
    accessor: 'template_name',
  },

  RESET_TEMPLATE: {
    id: 'is_modified',
    header: 'Réinitialiser',
    accessor: 'is_modified',
  },

  // SPECIFIC FOR CLOSED_TABLE

  // display for banks
  LAST_COMMENT: {
    id: 'last_comment',
    header: <IconChat alt="Commentaires" title="Commentaires" width="22px" height="22px" />,
    accessor: 'last_comment',
    label: <label>{OVERVIEW_LABEL.COMMENTS}</label>,
  },

  ISIN_CODE: {
    id: 'isin_code',
    header: 'Code ISIN',
    accessor: 'isin_code',
  },

  // display for banks with flag 'display.adherent_column_in_dashboard'
  ADHERENT: {
    id: 'adherent_name',
    header: 'Adhérent',
    accessor: 'adherent_name',
  },

  INVESTOR_NAME: {
    id: 'investor_name',
    header: 'Investisseur',
    accessor: 'investor_name',
  },
  CO_INVESTOR_NAME: {
    id: 'co_investor_name',
    header: 'Co-Investisseur',
    accessor: 'co_investor_name',
  },

  COMPANY_NAME: {
    id: 'company_name',
    header:
      selectDefaultBankName() === 'Banque Transatlantique Belgium'
        ? 'Société de gestion'
        : 'Entreprise',
    accessor: 'company_name',
  },

  SELLER_NAME: {
    id: 'seller_name',
    header: 'Vendeur',
    accessor: 'seller',
  },

  COMPANY_BOSS_MAIL: {
    id: 'userCompany.email',
    header: 'Email du représentant',
    accessor: 'userCompany.email',
  },

  OPERATION_TYPE: {
    id: 'operation_type',
    header: 'Opération',
    accessor: 'operation_type',
  },

  // display for banks and investor
  INVESTOR_IBAN: {
    id: 'investor_iban',
    header: 'IBAN investisseur(s)',
    accessor: 'investor_iban',
  },

  INVESTOR_SIGNED: {
    id: 'investor_signed',
    header: 'Date de signature LE',
    accessor: 'investor_signed',
  },

  INVESTMENT_DATE: {
    id: 'investment_date',
    header: 'Date de réalisation',
    accessor: 'real_estimated_invest_date',
  },

  TRADING_ACCOUNT_NUMBER: {
    id: 'trading_account_number',
    header: 'IBAN compte titre',
    accessor: 'trading_account_number',
  },

  SEND_DATE: {
    id: 'date_subscribe',
    header: "Date d'envoi",
    accessor: 'date_subscribe',
  },

  CLOSED_OR_CANCEL_DATE: {
    id: isUserCompany() ? 'status_change_date' : 'filtered_status_date',
    header: null,
    accessor: isUserCompany() ? 'status_change_date' : 'filtered_status_date',
  },
  // SPECIFIC FOR INVESTMENT HISTORY
  CREATED_DATE: {
    id: 'createdAt',
    header: "Date d'envoi",
    accessor: 'createdAt',
  },
  STEP: {
    id: 'step',
    header: 'Etape',
    accessor: 'step',
  },
  ENTITY_TYPE: {
    id: 'entity_type',
    header: 'Type',
    accessor: 'entity_type',
  },
  AUTHOR: {
    id: 'author_email',
    header: 'Auteur',
    accessor: 'author_email',
  },
  EMAIL: {
    id: 'email',
    header: 'Email',
    accessor: 'email',
  },
  // FUNDS
  FUND_NAME: {
    id: 'fund_name',
    header: 'Nom du Fonds',
    accessor: 'fund_name',
  },
  FUND_ISIN: {
    id: 'fund_isin',
    header: 'ISIN du Fonds',
    accessor: 'fund_isin',
  },

  // SPECIFIC FOR SHAREVALUATION LIST
  EMAIL_ICON: {
    id: 'email_sent',
    header: 'Mail envoyé',
    accessor: 'email_sent',
  },
  RAISON_SOCIALE: {
    id: 'company_name',
    header: 'Raison sociale',
    accessor: 'company_name',
  },
  SIREN: {
    id: 'company_siren',
    header: 'SIREN',
    accessor: 'company_siren',
  },
  N_VALEUR: {
    id: 'nb_value',
    header: 'Nombre de valeurs',
    accessor: 'nb_value',
  },
  N_INVESTISSEURS: {
    id: 'nb_investor',
    header: 'Investisseurs concernés',
    accessor: 'nb_investor',
  },
  UPDATE_DATE: {
    id: 'updatedAt',
    header: 'Date de mise à jour',
    accessor: 'updatedAt',
  },
  VALORISATIONS_ANNUELLES: {
    id: 'valorisations annuelles',
    header: 'Valorisations annuelles',
  },
  CONFIRMATION_POSITIONS: {
    id: 'confirmation positions',
    header: 'Confirmation des positions',
  },
  DIVIDEND_PAYMENT: {
    id: 'versement dividendes/rachats',
    header: 'Versement dividendes/rachats',
  },

  // SPECIFIC FOR SHAREVALUATION DETAILS

  // INVESTISSEURS vs N_INVESTISSEURS : get investors name
  INVESTISSEURS: {
    id: 'investors',
    header: 'Investisseurs concernés',
    accessor: 'investors',
  },
  PREVIOUS_COURSE: {
    id: 'previous_price',
    header: `Précédente valeur unitaire`,
    accessor: 'previous_price',
  },
  CURRENT_COURSE: {
    id: 'current_price',
    header: `Dernière valeur unitaire`,
    accessor: 'current_price',
  },
  NEW_VALUE: {
    id: 'price',
    header: 'Valeur unitaire',
    accessor: 'price',
  },
  EFFECTIVE_DATE: {
    id: 'effective_date',
    header: 'Date effective',
    accessor: 'effective_date',
  },
  NEW_EFFECTIVE_DATE: {
    id: 'new_effective_date',
    header: 'Nouvelle date effective',
    accessor: 'new_effective_date',
  },
  // specifix share valuation history
  VALUATION_DOC: {
    id: 'proof_doc_id',
    header: 'Justificatif associé',
    accessor: 'proof_doc_id',
  },

  // SPECIFIC FOR SHAREHOLDING
  QUANTITY: {
    id: 'quantity',
    header: 'Quantité',
    accessor: 'quantity',
  },
  PART_AMOUNT: {
    id: 'part_amount',
    header: 'Valeur unitaire',
    accessor: 'part_amount',
  },

  // SPECIFIC FOR SHAREHOLDING INVESTOR WALLET
  PAYMENT_MODE: {
    id: 'payment_mode',
    header: 'Compte',
    accessor: 'payment_mode',
  },
  QUANTITY_MVT: {
    id: 'quantity_delta',
    header: 'Quantité (Delta)',
    accessor: 'quantity',
  },
  QUANTITY_VALIDATED: {
    id: 'quantity_confirmed',
    header: 'Quantité validée',
    accessor: 'quantity_confirmed',
  },
  DATE_POSITION_VALIDATED: {
    id: 'last_validation_date',
    header: 'Dernière validation',
    accessor: 'last_validation_date',
  },
  BANK_NAME: {
    id: 'bank_name',
    header: 'Banque',
    accessor: 'bank_name',
  },
  INVESTOR_WALLET_EDITING: {
    id: 'manually_inserted',
    header: 'Source',
    accessor: 'manually_inserted',
  },
  INVESTOR_WALLET_DELETE: {
    id: 'manually_deleted',
    header: 'Actions',
    accessor: 'manually_inserted',
  },

  INVESTOR_POSITION_ATTESTATION: {
    id: 'uuid',
    header: 'Attestation générée',
    accessor: 'confirm_position_doc',
  },

  INVESTOR_POSITION_ACTION: {
    id: 'actions',
    header: 'Actions',
    accessor: 'uuid',
  },

  // SPECIFIC FOR POSITIONS DETAILS
  CONFIRMED_QUANTITY: {
    id: 'confirmed_quantity',
    header: 'Quantité confirmée',
    accessor: 'quantity',
  },

  // SPECIFIC FOR TRANSMIT
  INVESTOR_EMAIL: {
    id: 'investor_email',
    header: 'Investisseur',
    accessor: 'investor_email',
  },
  EMAIL_STATUS: {
    id: 'ctaEmailsStatus',
    header: 'Statut',
    accessor: 'ctaEmailsStatus',
  },
  UPDATE_TRANSMIT: {
    id: 'modifier transmis',
    header: 'Modifier',
    accessor: 'id',
  },
  CANCEL_OPERATION: {
    id: 'annuler',
    header: 'Annuler',
    accessor: 'id',
  },
  OPERATION_ACTIONS: {
    id: 'actions',
    header: 'Actions',
    accessor: 'id',
  },
  CONFIRM_OPERATION: {
    id: 'confirm',
    header: 'Actions',
    accessor: 'id',
  },

  // SPECIFIC FOR IN PROGRESS LIST
  RISK_INDICATOR: {
    id: 'risk_indicator',
    accessor: 'risk_indicator',
    header: (
      <IconAlertTriangle
        alt="Indicateur de risque"
        title="Indicateur de risque"
        width="25px"
        height="25px"
      />
    ),
    tooltip: "Présence d'indicateur de risque",
  },

  FLAG_INDICATOR: {
    id: 'is_flagged',
    accessor: 'is_flagged',
    header: (
      <IconFlag
        alt="Indicateur d'attention"
        title="Indicateur d'attention"
        width="25px"
        height="25px"
      />
    ),
    tooltip: "Présence d'une indicateur d'attention",
  },

  ACTIVE_ACTOR: {
    id: 'active_actor',
    header: 'Acteur',
    accessor: 'active_actor',
  },
  OPERATION_PROGRESSION: {
    id: 'progression',
    header: 'Progression',
    accessor: 'progression',
  },
  REMINDER: {
    id: 'reminder_occurence',
    header: (
      <IconBell
        alt={OVERVIEW_LABEL.REMINDER}
        title={OVERVIEW_LABEL.REMINDER}
        width="25px"
        height="25px"
      />
    ),
    accessor: 'reminder_occurence',
  },

  CHECK_PAYMENT: {
    id: 'check_payment',
    header: (
      <IconMoney
        alt={OVERVIEW_LABEL.CHECK_PAYMENT}
        title={OVERVIEW_LABEL.CHECK_PAYMENT}
        width="25px"
        height="25px"
      />
    ),
    accessor: 'check_payment',
    tooltip: 'Indicateur de virement',
  },

  // CHECKBOX IN BULK
  CHECKBOX: {
    id: 'checkbox',
  },

  // SPECIFIC FOR PLATINE
  PLATINE_DOCUMENTS_LIST: {
    id: 'documents_type',
    header: 'Type de documents',
    accessor: 'fileType',
  },

  PLATINE_DOCUMENT_NAME: {
    id: 'document_name',
    header: 'Nom du document',
    accessor: 'name',
  },

  PLATINE_CREATION_DATE: {
    id: 'creation_date',
    header: 'Date de création',
    accessor: 'dateCreate',
  },

  PLATINE_USER: {
    id: 'user',
    header: 'Utilisateur',
    accessor: 'createUser',
  },
  PLATINE_COMMENT: {
    id: 'comment',
    header: 'Commentaire',
    accessor: 'comment',
  },
  PLATINE_COMMENT_CREATION_DATE: {
    id: 'comment_creation_date',
    header: 'Date de création',
    accessor: 'dateCreate',
  },

  // dividende/coupon
  DEPOSIT_ACCOUNT: {
    id: 'deposit_account',
    header: `IBAN`,
    accessor: `deposit_account`,
  },
  AMOUNT_PER_PART: {
    id: 'amount_per_part',
    header: `Montant par part`,
    accessor: 'amount_per_part',
  },
  AMOUNT_TO_DEPOSIT: {
    id: 'amount_to_deposit',
    header: `Montant à verser`,
    accessor: 'amount_to_deposit',
  },
  AMOUNT_DEPOSIT: {
    id: 'amount_deposit',
    header: `Montant versé`,
    accessor: 'amount_deposit',
  },
  IBAN: {
    id: 'iban',
    header: `IBAN`,
    accessor: 'iban',
  },
  PAYMENT_IBAN: {
    id: 'payment_iban',
    header: `IBAN versement`,
    accessor: 'payment_iban',
  },
  NB_INVESTISSEURS: {
    id: 'nb_investor',
    header: `Nombre d'investisseurs`,
    accessor: 'nb_investor',
  },
  TRANSFER_DATE: {
    id: 'transfer_date',
    header: `Date de virement`,
    accessor: 'transfer_date',
  },
  DECLARATION_DATE: {
    id: 'declaration_date',
    header: `Date de déclaration`,
    accessor: 'declaration_date',
  },
  HISTORY_DETAIL: {
    id: 'history_detail',
    header: 'Détails',
    accessor: 'history_detail',
  },
  PROOF_DOC: {
    id: 'proof_doc',
    header: 'Justificatif',
    accessor: 'proof_doc',
  },
  INVESTOR: {
    id: 'investor',
    header: 'Investisseur',
    accessor: 'investor',
  },
  CO_INVESTOR: {
    id: 'co_investor',
    header: 'Co-Investisseur',
    accessor: 'co_investor',
  },
  COUNTRY: {
    id: 'country',
    header: 'Pays',
    accessor: 'country',
  },
  INVESTOR_REGISTERED_COUNTRY: {
    id: 'investor_registered_country',
    header: 'Pays',
    accessor: 'investor_registered_country',
  },
  POINTED: {
    id: 'pointed',
    header: 'Pointée',
    accessor: 'pointed',
  },

  // SPECIFIC FOR MOVEMENT ORDERS

  ACTION: {
    id: 'action',
    header: 'Action',
    accessor: 'statusCode',
  },
  EMITTER_NAME: {
    id: 'emitter_name',
    header: 'Raison sociale',
    accessor: 'emitter.name',
  },
  INVESTOR_IDENTITY: {
    id: 'investor_identity',
    header: `Identité de l'investisseur`,
    accessor: 'holders[0].identity',
  },
  MOVEMENT_ORDER_REASON: {
    id: 'movement_order_reason',
    header: `Motif de l'ODM`,
    accessor: 'operationType',
  },
  MOVEMENT_ORDER_STATUS: {
    id: 'movement_order_status',
    header: 'Statut ODM',
    accessor: 'statusName',
  },
  MOVEMENT_ORDER_ISSUE_DATE: {
    id: 'movement_order_issue_date',
    header: `Date d'émission`,
    accessor: 'creationDate',
  },
  MOVEMENT_ORDER_DETAILS: {
    id: 'movement_order_details',
    header: 'Détails',
    accessor: 'movement_order_details',
  },

  // fund call
  FUND_CALL_BANK_NAME: {
    id: 'bank_name',
    header: 'Établissements',
    accessor: 'name',
  },
  FUND_CALL_INVESTOR_NUMBER: {
    id: 'investor_number',
    header: 'Investisseurs',
    accessor: 'investorNumber',
  },
  FUND_CALL_RAISED_AMOUNT: {
    id: 'raised_amount',
    header: 'Engagement',
    accessor: 'raisedAmount',
  },
  FUND_CALL_APPEAL_AMOUNT: {
    id: 'appeal_amount',
    header: 'Montant appelé',
    accessor: 'appealAmount',
  },
  FUND_CALL_DEBITING_AMOUNT: {
    id: 'debiting_amount',
    header: 'Montant cumulé',
    accessor: 'debitingAmount',
  },
  FUND_CALL_SHARE_PREMIUM: {
    id: 'share_premium',
    header: 'Frais de prime',
    accessor: 'sharePremium',
  },
  FUND_CALL_CATCH_UP_AMOUNT: {
    id: 'catch_up_amount',
    header: 'Montant de rattrapage',
    accessor: 'catchUpAmount',
  },

  FUND_CALL_OPERATION_INVESOR_ID: {
    id: 'investor_identity',
    header: 'Identité',
    accessor: 'investor.identity',
  },

  FUND_CALL_OPERATION_BANK_NAME: {
    id: 'bank_name',
    header: 'Établissement',
    accessor: 'bank.name',
  },

  FUND_CALL_OPERATION_SUBSCRIPTION_DATE: {
    id: 'engagement_date',
    header: 'Date de souscription',
    accessor: 'createdAt',
  },

  FUND_CALL_OPERATION_RAISED_AMOUNT: {
    id: 'raised_amount',
    header: 'Engagement',
    accessor: 'raisedAmount',
  },

  FUND_CALL_OPERATION_APPEAL_AMOUNT: {
    id: 'appeal_amount',
    header: 'Montant appelé',
    accessor: 'appealAmount',
  },

  FUND_CALL_OPERATION_DEBITING_AMOUNT: {
    id: 'debiting_amount',
    header: 'Cumul versements',
    accessor: 'debitingAmount',
  },

  FUND_CALL_OPERATION_SHARE_PREMIUM: {
    id: 'share_premium',
    header: "Prime d'émission",
    accessor: 'sharePremium',
  },

  FUND_CALL_OPERATION_CATCH_UP_AMOUNT: {
    id: 'catch_up_amount',
    header: 'Montant de rattrapage',
    accessor: 'catchUpAmount',
  },

  // fund summary list
  FUND_APPEAL_STATUS: {
    id: 'status',
    header: 'Statut',
    accessor: 'status',
  },
  FUND_APPEAL_NAME: {
    id: 'name',
    header: 'Fonds',
    accessor: 'name',
  },
  FUND_APPEAL_ISIN: {
    id: 'isin',
    header: 'ISIN',
    accessor: 'isin',
  },
  FUND_APPEAL_PART_TYPE: {
    id: 'partType',
    header: 'Nature',
    accessor: 'partType',
  },
  FUND_APPEAL_CATEGORY: {
    id: 'category',
    header: 'Catégorie',
    accessor: 'category',
  },
  FUND_APPEAL_RAISED_AMOUNT: {
    id: 'totalRaisedAmount',
    header: 'Engagements',
    accessor: 'totalRaisedAmount',
  },
  FUND_APPEAL_NB: {
    id: 'nbAppeals',
    header: 'Appels',
    accessor: 'nbAppeals',
  },
  FUND_APPEAL_TOTAL_RATE: {
    id: 'totalAppealRate',
    header: '% appelé',
    accessor: 'totalAppealRate',
  },
  FUND_APPEAL_COMMUNICATION: {
    id: 'communications',
    header: 'Communications',
    accessor: 'communications',
  },
  FUND_APPEAL_DETAILS: {
    id: 'details',
    header: 'Détails',
    accessor: 'details',
  },
  // fund appeal details
  FUND_APPEAL_DETAIL_INVESTOR: {
    id: 'investor',
    header: 'Investisseur',
    accessor: 'raising.investor.identity',
  },
  FUND_APPEAL_DETAIL_CO_INVESTOR: {
    id: 'investor',
    header: 'Co-Investisseur',
    accessor: 'raising.coInvestor.identity',
  },
  FUND_APPEAL_DETAIL_BANK: {
    id: 'bank',
    header: 'Banque',
    accessor: 'raising.bank.name',
  },
  FUND_APPEAL_DETAIL_OPERATION_DATE: {
    id: 'subscription_date',
    header: 'Date de souscription',
    accessor: 'raising.createdAt',
  },
  FUND_APPEAL_DETAIL_COMMITMENT: {
    id: 'commitment',
    header: 'Engagements',
    accessor: 'raising.raisedAmount',
  },
  FUND_APPEAL_DETAIL_AMOUNT_PAYMENT: {
    id: 'appealAmount',
    header: 'Montant appelé',
    accessor: 'appealAmount',
  },
  FUND_APPEAL_DETAIL_CATCH_UP_AMOUNT: {
    id: 'catchUpAmount',
    header: 'Montant rattrapé',
    accessor: 'catchUpAmount',
  },
  FUND_APPEAL_DETAIL_SHARE_PREMIUM: {
    id: 'sharePremium',
    header: "Prime d'émission",
    accessor: 'sharePremium',
  },
  FUND_APPEAL_DETAIL_DEBITED_DATE: {
    id: 'debitedDate',
    header: 'Date de prélèvement',
    accessor: 'debitedDate',
  },
  FUND_APPEAL_DETAIL_PAYMENT_DEBITED: {
    id: 'debited',
    header: 'Prélèvement',
    accessor: 'debited',
  },
  // fund communications
  FUND_COMMUNICATION_DATE: {
    id: 'sentDate',
    header: 'Date',
    accessor: 'sentDate',
  },
  FUND_COMMUNICATION_RECIPIENT: {
    id: 'type',
    header: 'Destinataires',
    accessor: 'type',
  },
  FUND_COMMUNICATION_SUBJECT: {
    id: 'subject',
    header: 'Sujet',
    accessor: 'subject',
  },
  FUND_COMMUNICATION_CONTENT: {
    id: 'htmlBody',
    header: 'Détails',
    accessor: 'htmlBody',
  },
  FUND_COMMUNICATION_FORWARD: {
    id: 'forward',
    header: 'Transférer',
    accessor: 'forward',
  },
};

export const TABLE_NAME = {
  INVESTMENT_HISTORY: 'investmentHistoryList',
  OPERATION_TEMPLATE_LIST: 'operationTemplateList',
  CLOSED_LIST: 'closedList',
  IN_PROGRESS_LIST: 'inProgressList',
  TRANSMIT: 'transmitList',
  OPERATION_SUGGESTION: 'operationSuggestion',
  SHARES_VALUATION: 'sharesValuation',
  SHARES_VALUATION_POSITION: 'sharesValuationPosition',
  SHARES_VALUATION_POSITION_DETAILS: 'sharesValuationPositionDetails',
  SHARES_VALUATION_POSITION_DETAILS_HISTORY: 'sharesValuationPositionDetailsHistory',
  SHARES_VALUATION_DETAILS: 'sharesValuationDetails',
  SHARES_VALUATION_HISTORY_DETAILS: 'sharesValuationHistoryDetails',
  BULK_TABLE: 'bulkTable',
  SHAREHOLDING_LIST: 'shareHoldingList',
  INVESTOR_WALLET_TABLE: 'investorWalletTable',
  POSITION_DETAILS_TABLE: 'positionDetailsTable',
  POSITION_HISTORY_DETAILS_TABLE: 'positionHistoryDetailsTable',
  PLATINE_DOCUMENTS_LIST: 'platineDocumentslist',
  PLATINE_COMMENTS_LIST: 'platineCommentslist',
  EXPOST_CHARGES: 'expostCharges',
  EXPOST_CHARGES_PRODUCT: 'expostChargesProductTable',
  EXPOST_CHARGES_INVESTOR: 'expostChargesInvestorTable',
  EXPOST_CHARGES_OPERATION: 'expostChargesOperationTable',
  GENERATED_PDF_INJECTION: 'GeneratedPdfDocumentInjectionTable',
  CUSTOM_FIELDS_TABLE: 'customFieldsTable',
  TEMPLATE_DOCUMENT_TABLE_FIELDS: 'TemplateDocumentTableField',
  DIVIDEND_COUPON_INVESTORS: 'dividendCouponInvestorsTable',
  DIVIDEND_COUPON_REGROUPED_POSITION: 'dividendCouponRegroupedPositionTable',
  DIVIDEND_COUPON_HISTORY: 'dividendCouponHistoryTable',
  DIVIDEND_COUPON_HISTORY_DETAIL: 'dividendCouponHistoryDetailTable',
  MOVEMENT_ORDERS: 'movementOrdersTable',
  FUND_MANAGEMENT: 'fundManagement',
  FUND_APPEAL_DETAILS: 'fundAppealDetails',
  FUND_COMMUNICATION: 'fundCommunication',
  FUND_APPEAL_ESTABLISHMENT: 'establishment table',
  FUND_APPEAL_OPERATION: 'operations table',
};

export const TABLE_TEXT = {
  PARAMETERS_TEXT_BUTTON: "Paramétrer l'affichage des colonnes",
  ALL_TOGGLE_TEXT: 'Tout sélectionner',
  RESIZE_TEXT: 'Redimensionnement automatique',
  PAGINATION_INFOS: 'display informations about pagination',
  CHECKBOXES_INFOS: 'display informations about checkbox',
};

export const CHECKBOXES_LABEL = {
  check_payment: 'Virement',
  risk_indicator: `Indicateur de risque`,
  is_flagged: `Indicateur d'attention`,
  reminder_occurence: 'Relances',
  last_comment: 'Commentaires',
  email_sent: 'Email',
};

/*
declare module 'react-table' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface TableState<D extends object> extends UseSortByState<D> {}
}
*/

export const createCustomSortableHeader = headerDef => {
  return options => {
    let sortBy = {
      id: '',
      desc: false,
    };
    if (options.state && Array.isArray(options.state.sortBy) && options.state.sortBy.length > 0) {
      sortBy = options.state.sortBy[0];
    }
    return (
      <CustomHeader
        sorted={sortBy.id === options.column.id}
        sortDesc={sortBy.desc}
        tooltip={headerDef.tooltip}
        header={headerDef.header}
      />
    );
  };
};
